import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useInView } from "react-intersection-observer";
import { motion, useTransform, useViewportScroll, useSpring} from 'framer-motion'


const WSParallax = ({ children, inputYmax, outputYmax, className, xvalue }) => {

    const [elemStart, setElemStart] = useState(0);
    const [elemEnd, setElemEnd] = useState(0);
    const [elemHeight, setElemHeight] = useState(0);
    const ref = useRef(null);

    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY,[elemStart , elemEnd], [- elemHeight * 0.25, elemHeight * 0.25]); // ideal input was 0, 500 and output was 0, 650 [0,500],[0,650] )// 
    const y = useSpring(y2, { stiffness: 50, damping: 10 })

      //performed after dom is mutated
      useLayoutEffect(() => {

        if(!ref){
          return;
        }

        const onResize = () => {
          // Get the distance from the start of the page to the element start
        const rect = ref.current.parentNode.getBoundingClientRect();
        var bodyRect = document.body.getBoundingClientRect();

        //console.log("element "+className+" start = "+(rect.top + window.scrollY || window.pageYOffset));    //+(rect.top - bodyRect.top));
        //console.log("element "+className+" end = "+( rect.height + (rect.top + window.scrollY || window.pageYOffset)));
        //console.log("final "+className+" start = " + ((rect.top + window.scrollY || window.pageYOffset)-window.innerHeight));


       // let estart = (rect.top + window.scrollY || window.pageYOffset)-window.innerHeight < 0 ? 0 : ((rect.top + window.scrollY || window.pageYOffset)-window.innerHeight );
        //let eend = bodyRect.height - rect.bottom; //(estart + rect.height);

        /*console.log(className+"RECT "+rect.top + "RECT BOTTOM "+rect.bottom);*/

        let start;
        let end;

        //set start
        if((rect.top + window.scrollY || window.pageYOffset) < window.innerHeight){
          start = window.innerHeight * 0.1 //0;
        }
        else{
          start = (rect.top + window.scrollY || window.pageYOffset) - window.innerHeight;
        }

        // set end
          end = (rect.bottom + window.scrollY || window.pageYOffset);// + rect.height;

        setElemStart(start);//((rect.top + window.scrollY || window.pageYOffset) < window.innerHeight ? 0 : (rect.top + window.scrollY || window.pageYOffset)) //(estart)//(rect.top + window.scrollY || window.pageYOffset)   //(rect.top - bodyRect.top);
        setElemEnd(end);//(rect.bottom + window.scrollY || window.pageYOffset) + rect.height)//(( rect.height + (rect.top + window.scrollY || window.pageYOffset)));
        setElemHeight(rect.height);
       /* console.log("element "+className+" start = "+start);    //+(rect.top - bodyRect.top));
        console.log("element "+className+" end = "+end);
        console.log("element "+className+" start => end - height = "+(end-elemHeight));
        console.log("window height x width"+window.innerHeight+" x "+window.innerWidth); */
        //console.log("element "+className+" estart = "+(estart));    //+(rect.top - bodyRect.top));
        //console.log("element "+className+" eend = "+(eend))   //(estart + (rect.height)*1.25));
        //console.log("final start = " + ((rect.top + window.scrollY || window.pageYOffset)-window.innerHeight));

        //console.log("scrolltop ="+ref.current.scrollTop);
        }
        onResize()
        window.addEventListener('resize', onResize)
        
        

        //console.log("offset end-"+offsetEnd)
        window.addEventListener('scroll', (event) => {
         // console.log('scroll y '+scrollY.get())
        });

        return () => window.removeEventListener('resize', onResize)
      });

    return (
    
          <motion.div
            className={className}
            ref={ref}
            //transition={{ duration: 0.5, ease: "easeOut" }}
            transition={{ease: [0.17, 0.67, 0.83, 0.67]}}    //{ mass: 0.1, type: "spring" }}
            style={{ y: y}}
            initial= {{ y: - elemHeight * 0.25 }}
          >
            {children}
          </motion.div>
    );
}

export default WSParallax